@font-face {
  font-family: 'NoirStd-Medium';
  src: URL('./fonts/NoirStd-Medium.woff') format('woff');
}

@font-face {
  font-family: 'NoirStd-Regular';
  src: URL('./fonts/NoirStd-Regular.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'NoirStd-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.swiper-pagination-bullets {
  bottom: 0 !important;
  display: flex;
  flex-direction: row;
}

@media (max-width: 959px) {
  .swiper-pagination-bullets {
    justify-content: flex-end;
    width: calc(100% - 80px) !important;
  }
}
@media (min-width: 960px) {
  .swiper-pagination-bullets {
    justify-content: center;
  }
}

html {
  height  : 100%;
  overflow: hidden;
  position: relative;
}

.MuiInput-input, .MuiInputBase-root {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.MuiInput-input:hover, .MuiInputBase-root:hover, .MuiInput-input:focus, .MuiInputBase-root:focus {
  border-bottom-color: rgba(255, 255, 255, 1) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 1) !important;
}

.MuiInputBase-root::before {
  border-bottom-width: 0 !important;
}
.MuiInputBase-root::after {
  border-bottom-width: 0 !important;
}